/* AvatarCreationPage.css */

.avatar-creation-container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
}

.heading {
  margin-bottom: 1rem;
  color: #333;
  font-weight: bold;
  text-transform: uppercase;
}

.avatar-display {
  width: 300px; /* Adjust this width as needed */
  margin: 0 auto 1rem;
  padding: 1rem;
  background: linear-gradient(145deg, #ffffff, #ececec);
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  text-align: center;
  position: relative;
}

.avatar-display .avataaars {
  width: 150px;
  height: 150px;
  margin: 0 auto;
}

.message {
  color: #555;
  font-style: italic;
  border-top: 1px dashed #ccc;
  padding-top: 0.5rem;
  margin-top: 0.5rem;
}

.options-grid .MuiCard-root {
  margin: 8px;
  padding: 8px;
  width: auto;
}

.options-grid .back-button-grid-item {
  padding-top: 0;
  margin-top: -16px;
}

.options-grid .MuiButton-root {
  margin-top: -16px;
}

.features-carousel {
  display: flex;
  overflow-x: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.features-carousel::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.feature-card {
  min-width: 120px;
  margin: 0 16px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Add this line */
}

.options-carousel {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 8px 0; /* Add padding top and bottom for the carousel */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.options-carousel::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.option-card {
  min-width: 100px; /* Set a minimum width for the option cards */
  margin-right: 16px; /* Space between cards */
}

.carousel-container {
  display: flex;
  align-items: center;
}

.scroll-button {
  min-width: auto;
  margin: 0 8px;
}

.features-carousel {
  flex-grow: 1;
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
}

.features-carousel::-webkit-scrollbar {
  display: none;
}

.feature-card {
  min-width: 120px;
  margin: 0 16px;
  flex: 0 0 auto;
}


.feature-card .mini-avatar-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  margin-top: 8px;
  margin-bottom: 8px;
}

/* Add this class to the AvatarPreview component when size is 'small' */
.small-avatar-preview {
  width: 50px; 
  height: 50px; 
  
}

.mini-avatar-preview .avataaars {
  width: 100%;
  height: auto;
}

.custom-avatar-size .avataaars {
  width: 100px;
  height: 100px;
}

.feature-card .MuiCardContent-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}