/* index.css */
body {
  margin: 0;
  padding: 0;
}

.App-content {
  padding-top: 65px !important; /* Ensure this has higher specificity */
}


/*.landing-page {
  margin-top: 0 !important; /* Override for landing pages - the class is in the global.css
}
*/


/* Ensure other global styles are correctly set */
